<template>
    <div>
        <div v-if="!cargoItem">
            <DxLoadIndicator
                    id="medium-indicator"
                    :height="20"
                    :width="20"
            />
            Загрузка
        </div>
        <div v-if="cargoItem" class="table-responsive-md">
            <div class="row">
                <div class="col-12">
                    <div class="card shadow-sm mb-3">
                        <div class="bg-primary" style="border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem;">
                            <div class="row">
                                <div class="col-6 text-left text-white py-1">
                                    <small class="mb-2 px-3">{{ cargoItem.cargoLotNumber }}</small>
                                </div>
                                <div class="col-6 text-right text-white py-1">
                                    <small class="mb-2 px-3">{{ cargoItem.pickupDate | moment("DD.MM.YYYY") }}</small>
                                </div>
                            </div>
                            <div class="text-white px-3 pt-0 pb-2">
                                {{ cargoItem.cargo.descriptionOfCargo }}
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-2 col-sm-1">
                                    <i class="fas fa-map-marked-alt font-20 text-muted"></i>
                                </div>
                                <div class="col-10 col-sm-11">
                                    <span class="text-primary link-revert" @click="openRoute(cargoItem.cargoLotId)">{{ cargoItem.routeCaption }}</span>
                                    <div class="modal fade" :id="'routeModal'+cargoItem.cargoLotId" tabindex="-1"
                                         :aria-labelledby="'routeModalLabel'+cargoItem.cargoLotId"
                                         aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title"
                                                        :id="'routeModalLabel'+cargoItem.cargoLotId">Информация по
                                                        маршруту</h5>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <strong>Маршрут:</strong>
                                                    <p class="font-12">{{ cargoItem.routeCaption }}</p>
                                                    <strong>Адреса:</strong>
                                                    <div v-if="!selectRoute" class="mb-3">
                                                        <DxLoadIndicator
                                                            id="medium-indicator-routes"
                                                            :height="20"
                                                            :width="20"
                                                        />
                                                        Загрузка
                                                    </div>
                                                    <table v-if="selectRoute" class="table table-borderless font-12">
                                                        <tbody>
                                                        <tr v-for="(route, index) in selectRoute"
                                                            :key="route.routePointId">
                                                            <td class="pb-0">
                                                                <div class="row">
                                                                    <div class="col-12 pb-3 d-flex align-items-center"
                                                                         v-if="index > 0">
                                                                        <i class="fas fa-long-arrow-alt-down text-yellow"
                                                                           style="font-size: 25px"></i><strong
                                                                        class="text-primary ml-3"
                                                                        v-if="route.distance > 0">{{
                                                                            route.distance }} км</strong>
                                                                    </div>
                                                                    <div class="col-3 pr-0">
                                                                        <div v-if="route.operationType == 'rptLoading'"
                                                                             class="h-100 d-flex align-items-center">
                                                                            <i class="fas fa-arrow-right pr-1 text-danger"
                                                                               style="font-size: 20px"></i><i
                                                                            class="fas fa-truck-loading text-danger"
                                                                            style="font-size: 25px"></i>
                                                                        </div>
                                                                        <div v-if="route.operationType == 'rtpUnloading'"
                                                                             class="h-100 d-flex align-items-center">
                                                                            <i class="fas fa-arrow-left pr-1 text-secondary"
                                                                               style="font-size: 20px"></i><i
                                                                            class="fas fa-truck-loading text-secondary"
                                                                            style="font-size: 25px"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 pl-0">
                                                                        <span v-if="isViewTime(route.operationTime)">{{ route.operationTime | moment("DD.MM.YYYY HH:mm") }}<br>{{ route.address.localityDisplay }}</span>
                                                                        <span v-if="!isViewTime(route.operationTime)">{{ route.operationTime | moment("DD.MM.YYYY") }}<br>{{ route.address.localityDisplay }}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary mr-2"
                                                            data-dismiss="modal">Закрыть
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2 col-sm-1">
                                    <i class="fas fa-truck-moving font-20 text-muted"></i>
                                </div>
                                <div class="col-10 col-sm-11">
                                    {{ cargoItem.cargo.rollingStockRequestDisplay }}
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2 col-sm-1">
                                    <i class="fas fa-exclamation-triangle font-20 text-warning"></i>
                                </div>
                                <div class="col-10 col-sm-11">
                                    Особые условия: {{ cargoItem.cargo.additionCarriageTerm }}
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-6 col-sm-4">
                                    <button type="button"
                                            class="btn btn-outline-primary btn-sm" @click="openOffers(cargoItem.cargoLotId)">
                                        <span class="small">ВСЕ ПРЕДЛОЖЕНИЯ</span>
                                    </button>
                                    <div class="modal fade" :id="'offersModal'+cargoItem.cargoLotId" tabindex="-1"
                                         :aria-labelledby="'offersModalLabel'+cargoItem.cargoLotId"
                                         aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title"
                                                        :id="'offersModalLabel'+cargoItem.cargoLotId">
                                                        Предложения</h5>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div v-if="!selectOffers" class="mb-3">
                                                        <DxLoadIndicator
                                                            id="medium-indicator-offers"
                                                            :height="20"
                                                            :width="20"
                                                        />
                                                        Загрузка
                                                    </div>
                                                    <div v-if="selectOffers && selectOffers.length == 0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                На данный момент предложений нет.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table v-if="selectOffers && selectOffers.length > 0" class="table mb-0 font-12">
                                                        <tbody>
                                                        <tr v-for="(offer, index) in selectOffers"
                                                            :key="offer.cargoOfferId">
                                                            <td style="border: 0px; width: 5px;" class="pl-1 pr-0"><i v-if="offer.status == 'oisApproved'" class="fas fa-check-circle text-success"></i></td>
                                                            <td style="border: 0px; width: 10px;" class="pr-0">{{ index+1 }}:</td>
                                                            <td style="border: 0px; width: 130px">{{
                                                                    offer.timeCreate | moment("DD.MM.YYYY HH:mm") }}
                                                            </td>
                                                            <td style="border: 0px">{{
                                                                    offer.rollingStockUnitTypeName }}
                                                            </td>
                                                            <td class="text-right"
                                                                style="border: 0px; width: 120px"><strong>{{
                                                                    offer.amount | formatMoney }}  {{ offer.currencyShortName }}</strong></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary mr-2"
                                                            data-dismiss="modal">Закрыть
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-4 text-right text-sm-center">

                                </div>
                                <div v-if="!cargoItem.agentOffer" class="col-12 col-sm-4 pt-2 pt-sm-1 text-right">
                                    <span class="small text-muted">НЕТ ПРЕДЛОЖЕНИЯ</span>
                                </div>
                                <div v-if="cargoItem.agentOffer" class="col-12 col-sm-4 pt-2 pt-sm-1 text-right">
                                    <span class="text-muted">{{ cargoItem.agentOffer.amount | formatMoney }} {{ cargoItem.agentOffer.currencyShortName }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!offerIsLocked" class="mb-3 text-center">
                        <button type="button" class="btn btn-outline-danger btn-block btn-sm"
                                @click="DelOffer()">
                            Снять предложение с тендера
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import localStore from '../store';
    import {httpClient} from '../../../shared/services';
    import moment from 'moment';

    export default {
        name: "CargoLot",
        props: ['cargoLotId', 'Agent', 'offerId', 'offerIsLocked', 'getOffers'],
        components: {
            DxLoadIndicator
        },
        data() {
            return {
                cargoItem: null,
                selectRoute: null,
                selectOffers: null
            }
        },
        mounted() {
            this.getCargoLot();
        },
        methods: {
            getCargoLot() {
                localStore.dispatch('getCargoLot', {tokenId: this.Agent.tokenId, cargoLotId: this.cargoLotId}).then(() => {
                    this.cargoItem = localStore.state.cargoLot;
                });
            },
            openMarketplace() {
                this.$router.push({name: 'Marketplace' });
            },
            openRoute(cargoLotId, tokenId = '') {
                this.selectRoute = null;

                if (this.Agent) {
                    tokenId = this.Agent.tokenId;
                }

                localStore.dispatch('getRoute', {
                    tokenId,
                    cargoLotId
                }).then(() => {
                    this.selectRoute = localStore.state.route;
                });

                $('#routeModal' + cargoLotId).modal('toggle');
            },
            openOffers(cargoLotId, tokenId = '') {
                this.selectOffers = null;

                if (this.Agent) {
                    tokenId = this.Agent.tokenId;
                }

                localStore.dispatch('getOffers', {
                    tokenId,
                    cargoLotId
                }).then(() => {
                    this.selectOffers = localStore.state.offers;
                });

                $('#offersModal' + cargoLotId).modal('toggle');
            },
            isViewTime(pickupDate) {
                return moment(pickupDate).format('HH:mm') != '00:00';
            },
            DelOffer() {
                let isConfirm = confirm("Вы действительно хотите удалить это предложение?");
                if(isConfirm) {
                    this.errorMessageEditOffer = "";
                    this.isSendOffer = true;

                    httpClient.delete(`v2/Carrier/Offers/${this.offerId}?tokenId=${this.Agent.tokenId}`).then(() => {
                        this.getOffers();
                        this.errorMessageEditOffer = "";
                    }).catch(this.handleErrorDelOffer);
                }
            },
        }
    }
</script>

<style scoped>

</style>
