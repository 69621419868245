<template>
    <div>
        <vue-title title="Ценовые предложения | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Ценовые предложения</h1>
            </div>
            <div>
                <DxDataGrid
                        id="gridContainerOffers"
                        :ref="dataGridRefKeyOffers"
                        :data-source="storeOffers"
                        :columns="columnsOffers"
                        :show-borders="true"
                        :row-alternation-enabled="true"
                        :show-row-lines="true"
                        :customize-columns="customizeColumnsOffers"
                        :paging="{pageSize: pageSize, enabled: true}"
                        :pager="pager"
                        :remote-operations="true"
                        :filter-row="{visible: true}"
                        @row-click="onRowClickOffers"
                >
                    <DxSelection mode="single"/>
                    <DxHeaderFilter
                            :visible="true"
                    />
                    <DxMasterDetail
                            :enabled="true"
                            template="masterDetailTemplate"
                    />
                    <template #masterDetailTemplate="{ data }">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <CargoLot
                                        v-if="selectedRowKey == data.key"
                                        :cargoLotId="data.data.rollingStockId"
                                        :offerId="data.key"
                                        :offerIsLocked="data.data.locked"
                                        :agent="Agent"
                                        :getOffers="getOffers"
                                />
                            </div>
                            <div class="col-12 col-lg-6">
                                <Offer
                                        v-if="selectedRowKey == data.key"
                                        :offerId="data.key"
                                        :agent="Agent"
                                        :getOffers="getOffers"
                                />
                            </div>
                        </div>
                    </template>
                    <template #locked-approved-cell-template="{ data }">
                        <div class="d-flex justify-content-around font-18" v-html="getStatus(data.data.status)"></div>
                    </template>
                </DxDataGrid>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes-2';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import { DxDataGrid, DxSelection, DxMasterDetail, DxHeaderFilter } from 'devextreme-vue/data-grid';
    import CustomStore from 'devextreme/data/custom_store';
    import moment from 'moment';

    import CargoLot from './detail/CargoLot';
    import Offer from './detail/Offer';

    const dataGridRefKeyOffers = "offers-data-grid";

    export default {
        name: "CarrierOffers",
        components: {
            Header,
            Footer,
            DxSelection,
            DxDataGrid,
            DxMasterDetail,
            Offer,
            CargoLot,
            DxHeaderFilter
        },
        data() {
            return {
                headerRoutes,
                dataGridRefKeyOffers,
                selectedRowKey: "",
                storeOffers: null,
                columnsOffers: [
                    {
                        caption: 'Состояние',
                        dataField: 'status',
                        allowSorting: true,
                        allowFiltering: false,
                        allowHeaderFiltering: true,
                        width: 90,
                        cellTemplate: "locked-approved-cell-template",
                        headerFilter: {
                            dataSource: [{
                                text: 'На рассмотрении',
                                value: 'oisProcessing'
                            }, {
                                text: 'Одобрено',
                                value: 'oisApproved'
                            }, {
                                text: 'Отклонено',
                                value: 'oisRejected'
                            }]
                        }
                    },
                    {
                        caption: 'Номер лота',
                        dataField: 'cargoLotNumber',
                        allowSorting: true,
                        width: 120,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Дата предложения',
                        dataField: 'date',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowResizing: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Начало маршрута',
                        dataField: 'firstLocality.localityDisplay',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Окончание маршрута',
                        dataField: 'lastLocality.localityDisplay',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Тип ПС',
                        dataField: 'rollingStockUnitTypeName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Стоимость предложения',
                        dataField: 'amount',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false,
                        width: 140,
                        customizeText: this.formatMoney2
                    },
                    {
                        caption: 'Валюта',
                        dataField: 'currencyName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false,
                        width: 100,
                    }
                ],
                pager: {
                    allowedPageSizes: [5, 10, 15, 30],
                    showInfo: true,
                    infoText: 'Страница {0} из {1} (Всего {2})',
                    showNavigationButtons: true,
                    showPageSizeSelector: true,
                    visible: true
                },
                pageSize: 10,
            }
        },
        created() {
            window.addEventListener("resize", this.resizeEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeEventHandler);
        },
        mounted() {
            this.getOffers();
        },
        methods: {
            getOffers() {
                this.storeOffers = new CustomStore({
                    key: 'offerId',
                    byKey: async function (key) {
                        if(key) {
                            console.log(key);
                        }
                    },
                    load: (loadOptions) => {

                        let tokenId = this.Agent.tokenId;
                        let sortStr = '';
                        let filterStr = '';
                        let takeStr = '';
                        let skeepStr = '';

                        if(loadOptions.take) {
                            takeStr = `&take=${loadOptions.take}`;
                        }

                        if(loadOptions.skip) {
                            skeepStr = `&skeep=${loadOptions.skip}`;
                        }

                        if (loadOptions.sort) {
                            let sort = loadOptions.sort[0];
                            sortStr = `&order_field=${sort.selector}&order_desc=${sort.desc}`;
                        }

                        if (loadOptions.filter) {

                            if(loadOptions.filter.columnIndex != undefined) { // Фильтрация только по одному полю
                                switch (loadOptions.filter.columnIndex) {
                                    case 2:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            let dateFilter = moment(loadOptions.filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], dateFilter);
                                        }
                                        break;
                                    default:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], loadOptions.filter[2]);
                                        }
                                        break;
                                }

                                filterStr = filterStr ? `&filter={"and":[${filterStr}]}` : '';
                            } else { // Фильтрация по нескольким полям
                                if(loadOptions.filter[0] != 'offerId') {
                                    if(loadOptions.filter[0] != '!') {
                                        filterStr = this.getFilterGroup(loadOptions.filter);
                                    } else {
                                        if (Array.isArray(loadOptions.filter[1][0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter[1], true);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[1][0], '<>', loadOptions.filter[1][2]);
                                        }
                                    }

                                    filterStr = filterStr ? `&filter=${filterStr}` : '';
                                }
                            }
                        }

                        return httpClient.get(`v2/Carrier/Offers?tokenId=${tokenId}${takeStr}${skeepStr}${sortStr}${filterStr}`)
                            .then((offers) => {
                                return {
                                    key: 'offerId',
                                    data: offers.data.items,
                                    totalCount: offers.data.totals,
                                    summary: offers.data.totals,
                                    groupCount: offers.data.count,
                                    userData: offers.data,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });
            },
            getFilterOp(op) {
                switch (op) {
                    case '>':
                        return 'gt';
                    case '>=':
                        return 'gte';
                    case '<':
                        return 'lt';
                    case '=<':
                    case '<=':
                        return 'lte';
                    case '=':
                        return 'eq';
                    case '<>':
                        return 'neq';
                    case 'contains':
                        return 'con';
                    case 'notcontains':
                        return 'ncon';
                    case 'startswith':
                        return 'sw';
                    case 'endswith':
                        return 'ew';
                }
            },
            getFilterItem(field_name, op, value) {
                if(field_name == 'amount') {
                    value = `${value}`;
                } else {
                    switch (value) {
                        case true:
                        case false:
                            value = `${value}`;
                            break;
                        default:
                            value = `"${value}"`;
                            break;
                    }
                }

                return `{"${field_name}":{"op":"${this.getFilterOp(op)}","value":${value}}}`;
            },
            getFilterGroup(group_filters, isNo = false) {
                let condition = '';
                let filter_items = '';

                group_filters.forEach(function (filter) {
                    if (Array.isArray(filter)) {
                        if(Array.isArray(filter[0])) {
                            filter_items += this.getFilterGroup(filter) + ',';
                        } else {
                            if(filter[0] == '!') {
                                if(Array.isArray(filter[1][0])) {
                                    filter_items += this.getFilterGroup(filter[1], true) + ',';
                                } else {
                                    filter_items += this.getFilterItem(filter[1][0], '<>', filter[1][2]) + ',';
                                }
                            } else {
                                switch (group_filters.columnIndex) {
                                    case 2:
                                        var dateFilter1 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                        filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter1) + ',';
                                        break;
                                    default:
                                        switch (filter.columnIndex) {
                                            case 2:
                                                var dateFilter2 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter2) + ',';
                                                break;
                                            default:
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), filter[2]) + ',';
                                                break;
                                        }
                                        break;
                                }
                            }
                        }
                    } else {
                        condition = filter;
                    }
                }, this);

                filter_items = filter_items.replace(/,$/, "");

                return `{"${condition}":[${filter_items}]}`;
            },
            changeColumns(columns) {
                if(columns.length > 0) {
                    columns[0].width = '100px';
                    columns[1].visible = true;
                    columns[2].visible = true;
                    columns[3].visible = true;
                    columns[4].visible = true;
                    columns[5].visible = true;
                    columns[6].visible = true;
                    columns[7].visible = true;

                    switch (this.$mq) {
                        case 'sm':
                            columns[0].width = '100px';
                            //columns[1].width = '100%';
                            columns[1].visible = true;
                            columns[2].visible = true;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = false;
                            columns[7].visible = false;
                            break;
                        case 'md':
                        case 'lg':
                            columns[0].width = '100px';
                            columns[1].visible = true;
                            columns[2].visible = true;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = true;
                            columns[7].visible = true;
                            break;
                    }
                }

                return columns;
            },
            customizeColumnsOffers(columns) { //columns
                this.changeColumns(columns);
            },
            onRowClickOffers(e) {
                if(e.rowType == "data" && !e.event.originalEvent.currentTarget.className.includes('dx-button')) {
                    if(this.selectedRowKey != e.key) {
                        if(this.selectedRowKey != "") {
                            this.dataGrid.collapseRow(this.selectedRowKey);
                            this.selectedRowKey = "";
                        }

                        this.selectedRowKey = e.key;

                        this.dataGrid.expandRow(this.selectedRowKey);
                    } else {
                        this.dataGrid.collapseRow(this.selectedRowKey);

                        this.selectedRowKey = "";
                    }
                }
            },
            resizeEventHandler() { // e
                this.dataGrid.option('columns', this.changeColumns(this.dataGrid.option().columns));
                this.dataGrid.repaint();
            },
            getStatus(status) {
                switch (status) {
                    case 'oisProcessing':
                        return '<i class="fas fa-eye text-secondary" title="На рассмотрении"></i>';

                    case 'oisApproved':
                        return '<i class="fas fa-lock text-danger" title="Блокировано"></i><i class="fas fa-check-square text-success" title="Одобрено"></i>';

                    case 'oisRejected':
                        return '<i class="fas fa-lock text-danger" title="Блокировано"></i><i class="fas fa-ban text-danger" title="Отклонено"></i>';
                }

                return '';
            },
            formatMoney2(options) {
                return this.$options.filters.formatMoney2(options.value);
            },
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ]),
            dataGrid: function() {
                return this.$refs[dataGridRefKeyOffers].instance;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
