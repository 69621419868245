<template>
    <div>
        <div v-if="!offer">
            <DxLoadIndicator
                    id="medium-indicator"
                    :height="20"
                    :width="20"
            />
            Загрузка
        </div>
        <div v-if="offer" class="table-responsive-md">
            <div class="row select-offer mb-3">
                <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Дата предложения:
                    </p>
                    <p class="item-text">
                        {{ offer.date | moment("DD.MM.YYYY") }}
                    </p>
                </div>
                <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Имя пользователя:
                    </p>
                    <p class="item-text">
                        {{ offer.userName ? offer.userName : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2 mb-0">
                        Состояние:
                    </p>
                    <p class="item-text" v-html="getStatusName(offer)"></p>
                </div>
                <div class="col-12 mb-0 mt-3 d-flex flex-row flex-md-column justify-content-start" v-if="!isLocked">
                    <p class="mr-2">
                        ЗПп:
                    </p>
                    <p class="item-text" v-if="offer.primaryDocumentCaption">
                        <button :id="'btn-primaryDocument-caption-'+offer.contractId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openPrimaryDocument(offer.primaryDocumentId)">{{ offer.primaryDocumentCaption }}</button>
                    </p>
                    <p class="item-text" v-if="!offer.primaryDocumentCaption">
                        -
                    </p>
                </div>
                <div class="col-12 my-3 d-flex flex-row flex-md-column justify-content-start" v-if="isLocked">
                    <p class="mr-2">
                        ЗПп:
                    </p>
                    <p class="item-text" v-if="offer.primaryDocumentCaption">
                        <button :id="'btn-primaryDocument-caption-'+offer.contractId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openPrimaryDocument(offer.primaryDocumentId)">{{ offer.primaryDocumentCaption }}</button>
                    </p>
                    <p class="item-text" v-if="!offer.primaryDocumentCaption">
                        -
                    </p>
                </div>
                <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start" v-if="isLocked">
                    <p class="mr-2">
                        Тип ПС:
                    </p>
                    <p class="item-text">
                        {{ offer.rollingStockUnitTypeName ? offer.rollingStockUnitTypeName : '-' }}
                    </p>
                </div>
                <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start" v-if="isLocked">
                    <p class="mr-2">
                        Стоимость:
                    </p>
                    <p class="item-text">
                        {{ offer.amount ? offer.amount : '-'  | formatMoney2 }}
                    </p>
                </div>
                <div class="col-12 col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-start" v-if="isLocked">
                    <p class="mr-2">
                        Валюта:
                    </p>
                    <p class="item-text">
                        {{ offer.currencyName ? offer.currencyName : '-' }}
                    </p>
                </div>
                <div class="col-12 d-flex flex-row flex-md-column justify-content-start" v-if="isLocked">
                    <p class="mr-2">
                        Комментарий:
                    </p>
                    <p class="item-text">
                        {{ offer.note ? offer.note : '-' }}
                    </p>
                </div>
            </div>
            <div v-if="!isLocked">
                <div v-if="errorMessageEditOffer"
                     class="alert alert-danger mb-3" role="alert">
                    <strong>Внимание!</strong> {{ errorMessageEditOffer }}
                </div>
                <form class="offer-form" @submit.prevent="onSubmit">
                    <DxForm
                            :col-count="1"
                            :form-data="offerEdit"
                            :label-location="labelLocation"
                            ref="editOfferFormRef"
                            :show-colon-after-label="true"
                            :show-validation-summary="true"
                            :disabled="isSendOffer"
                    >
                        <DxGroupItem :colCount="3">
                            <DxColCountByScreen :xs="1" :sm="1"/>
                            <DxSimpleItem
                                    :editor-options="{
                                          value: offerEdit.rollingStockUnitTypeId,
                                          dataSource: storeRollingStockUnitTypes,
                                          displayExpr: 'rollingStockUnitTypeName',
                                          valueExpr: 'rollingStockUnitTypeId',
                                          showClearButton: true,
                                          showDataBeforeSearch: false
                                          }"
                                    data-field="rollingStockUnitTypeId"
                                    editor-type="dxSelectBox"
                            >
                                <DxLabel text="Тип ПС"/>
                                <DxRequiredRule message="Укажите Тип ПС"/>
                            </DxSimpleItem>
                            <DxSimpleItem
                                    :editor-options="{value: offerEdit.amount}"
                                    data-field="amount"
                                    editor-type="dxNumberBox"
                            >
                                <DxLabel text="Стоимость"/>
                                <DxRequiredRule/>
                            </DxSimpleItem>
                            <DxSimpleItem
                                    :editor-options="{value: offerEdit.currencyId, items: currencys, displayExpr: 'сurrencyName', valueExpr: 'сurrencyId', showClearButton: true, searchEnabled: true}"
                                    data-field="currencyId"
                                    editor-type="dxSelectBox"
                            >
                                <DxLabel text="Валюта"/>
                                <DxRequiredRule message="Укажите Валюту"/>
                            </DxSimpleItem>
                        </DxGroupItem>
                        <DxSimpleItem
                                data-field="note"
                                :editor-options="{value: offerEdit.note}"
                        >
                            <DxLabel text="Комментарий"/>
                        </DxSimpleItem>
                        <DxButtonItem
                                :button-options="buttonOptionsSave"
                                cssClass="mt-2"
                                horizontalAlignment="right"
                        />
                    </DxForm>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import localStore from '../store';
    import {httpClient} from '../../../shared/services';
    import {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxButtonItem,
        DxRequiredRule,
        DxGroupItem,
        DxColCountByScreen
    } from 'devextreme-vue/form';
    import CustomStore from 'devextreme/data/custom_store';

    export default {
        name: "DetailOffer",
        props: ['offerId', 'Agent', 'getOffers'],
        components: {
            DxLoadIndicator,
            DxForm,
            DxSimpleItem,
            DxLabel,
            DxButtonItem,
            DxRequiredRule,
            DxGroupItem,
            DxColCountByScreen,
        },
        data() {
            return {
                offer: null,
                offerEdit: {
                    rollingStockUnitTypeId: "",
                    amount: "",
                    currencyId: "",
                    note: ""
                },
                labelLocation: 'top',
                currencys: null,
                buttonOptionsSave: {
                    text: 'Сохранить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                buttonOptionsDel: {
                    text: 'Удалить',
                    type: 'danger',
                    useSubmitBehavior: false,
                    onClick: this.DelOffer
                },
                rollingStockId: "",
                storeRollingStockTypeId: "",
                storeRollingStockUnitTypes: new CustomStore({
                    key: 'rollingStockUnitTypeId',
                    byKey: function () {
                        //console.log(key);
                        return {
                            "rollingStockUnitTypeId": window.rollingStockUnitTypeId,
                            "rollingStockUnitTypeName": window.rollingStockUnitTypeName
                        };
                    },
                    load: (loadOptions) => {
                        let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                        let filter = searchValue != '' ? `,{"rollingStockUnitTypeName":{"op":"con","value":"${searchValue}"}}` : '';

                        return httpClient.get(`v2/Catalog/RollingStockUnitTypes?filter={"rollingStockTypeId":{"op":"eq","value":"${this.storeRollingStockTypeId}"}}${filter}`)
                            .then((data) => {
                                return {
                                    key: 'rollingStockUnitTypeId',
                                    data: data.data.items,
                                    totalCount: data.totals,
                                    summary: data.totals,
                                    groupCount: data.count,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                }),
                isLocked: false,
                isSendOffer: false,
                errorMessageEditOffer: ""
            }
        },
        mounted() {
            this.getCurrencys();
            this.getOffer();
        },
        methods: {
            getOffer() {
                localStore.dispatch('getOffer', {tokenId: this.Agent.tokenId, offerId: this.offerId}).then(() => {
                    this.offer = localStore.state.offer;

                    this.storeRollingStockTypeId = this.offer.cargo.rollingStockTypeId;
                    this.rollingStockId = this.offer.rollingStockId;
                    this.errorMessageEditOffer = "";

                    window.rollingStockUnitTypeId = this.offer.rollingStockUnitTypeId;
                    window.rollingStockUnitTypeName = this.offer.rollingStockUnitTypeName;

                    this.offerEdit = {
                        rollingStockUnitTypeId: this.offer.rollingStockUnitTypeId,
                        amount: this.offer.amount,
                        currencyId: this.offer.currencyId,
                        note: this.offer.note,
                    };

                    this.isLocked = this.offer.locked;
                    this.isSendOffer = false;
                });
            },
            getCurrencys() {
                localStore.dispatch('getCurrencys').then(() => {
                    this.currencys = localStore.state.currencys;
                });
            },
            onSubmit: async function () {
                this.errorMessageEditOffer = "";
                this.isSendOffer = true;

                const {rollingStockUnitTypeId, amount, currencyId, note} = this.offerEdit;

                httpClient.post(`v2/Carrier/Offers?tokenId=${this.Agent.tokenId}`, {
                    offerId: this.offerId,
                    rollingStockUnitTypeId: rollingStockUnitTypeId,
                    amount: amount,
                    currencyId: currencyId,
                    note: note
                }).then(() => {
                    this.getOffers();
                }).catch(this.handleErrorEditOffer);
            },
            handleErrorEditOffer: function (error) {
                this.errorMessageEditOffer = "Ошибка при изменении предложения. Проверьте указанные вами данные и попробуйте еще раз.";
                this.isSendOffer = false;
                console.error(error);
            },
            getStatusName(offer) {

                let status = '<span class="badge badge-secondary-custom font-12"><i class="fas fa-eye mr-2" title="На рассмотрении"></i>На рассмотрении</span>';

                if(offer.locked && offer.approved) {
                    status = '<span class="badge badge-success font-12"><i class="fas fa-check-square mr-2" title="Одобрено"></i>Одобрено</span>';
                } else if(offer.locked && !offer.approved) {
                    status = '<span class="badge badge-danger font-12"><i class="fas fa-ban mr-2" title="Отклонено"></i>Отклонено</span>';
                }

                return status;
            },
            handleErrorDelOffer: function (error) {
                this.errorMessageEditOffer = "Ошибка при удалении предложения. Попробуйте еще раз.";
                this.isSendOffer = false;
                console.error(error);
            },
            openPrimaryDocument(primaryDocumentId) {
                let route = this.$router.resolve({name: 'PrimaryDocsPrimaryDocument', params: {primaryDocumentId: primaryDocumentId}});
                window.open(route.href, '_blank');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-offer {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
